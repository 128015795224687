<template>
  <v-dialog
    scrollable
    persistent
    :width="600"
    v-model="dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title class="white--text" v-text="$t('userAuth.resetPassword')" />
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          class="mt-5"
          lazy-validation
        >
          <v-text-field
            v-model="password"
            :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isShowPassword ? 'text' : 'password'"
            :label="`${$t('common.password')}*`"
            :rules="passwordRules"
            @click:append="isShowPassword = !isShowPassword"
          />

          <v-text-field
            v-model="confirmPassword"
            :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isShowPassword ? 'text' : 'password'"
            class="mt-4"
            :label="`${$t('common.confirmPassword')}*`"
            :rules="confirmPasswordRules"
            @click:append="isShowPassword = !isShowPassword"
          />

          <v-alert
            v-if="errorMessage"
            border="right"
            colored-border
            type="error"
            elevation="2"
            class="mt-2 text-left"
            dismissible
          >
            {{ errorMessage }}
          </v-alert>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          :disabled="!validForm"
          @click="changePassword"
        >
          {{ $t('common.reset') }}
        </v-btn>

        <v-btn
          class="error px-4"
          @click.native="dialog = false">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ResetPasswordDialog',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    data() {
      return {
        password: '',
        confirmPassword: '',
        validForm: true,
        errorMessage: '',
        isShowPassword: false,
        passwordRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.password') }),
          (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || this.$t('error.invalidPassword'),
        ],
        confirmPasswordRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.confirmPassword') }),
          (v) => v === this.password || this.$t('error.confirmPasswordNotMatch'),
        ],
      }
    },

    watch: {
      dialog (value) {
        this.password = ''
        this.confirmPassword = ''
      },
    },

    methods: {
      changePassword() {
        this.$emit('change-password', this.password)
      }
    },
  }
</script>
